import React, { useState } from "react";
import Language from "../language";
import { languages } from "../../enums";
import './style.css'

const baseURL = `${process.env.PUBLIC_URL}/assets/`;

const Header = ({ lang = false, partnerLogo = '', onError = () => {} }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]); // Default to Armenian

    return (
        <div className="heading">
            {!partnerLogo?.length ?
                <img src={`${baseURL}images/logo.svg`} alt="Logo"/> :
                <img src={partnerLogo} alt="Logo" onError={onError}/>
            }
            {lang ?
                <Language
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />
            :null}
        </div>
    );
};

export default Header;

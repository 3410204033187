import { endpoints } from "../../configs";
import axios from "axios";
import {t} from "i18next";

const baseURL = `${process.env.PUBLIC_URL}/assets/partners/`;
export const setMetaImage = (imageUrl) => {
    document.querySelector('meta[property="og:image"]').setAttribute('content', imageUrl);;
}
export const catchError = (setError, response) => {
    if (response?.data?.Code === 'soldout') {
        setError({status: true, image: 'empty-box', text: t('soldOut')})
    } else if (response?.data?.Code === 'link-unavailable') {
        setError({status: true, image: 'empty-browser', text: t('unavailableLink')})
    } else {
        setError({status: true, image: 'error-browser', text: t('technical')})
    }
}
export const getPayment = (setLoading, setLinkData, setError, setShowInfo, setPartnerLogo, id, i18n) => {
    axios
        .get(`${endpoints.getPayment}${id}`)
        .then((response) => {
            if (response?.status === 200 && response?.data) {
                setLinkData(response.data);
                setShowInfo(!response.data?.allowAnonymous);
                setPartnerLogo(`${baseURL}${response.data?.partnerId}.svg`)
                setLoading(false);
                setMetaImage(response.data?.imageUrl)
                i18n.changeLanguage(response.data?.language);
            }
        })
        .catch((error) => {
            const { response } = error;
            response && catchError(setError, response)
            setLoading(false);
        });
};

export const getStatement = (setStatementData, setLoading, setError, id) => {
    axios
        .get(`${endpoints.getStatement}${id}`)
        .then((response) => {
            if (response?.status === 200 && response?.data) {
                setStatementData(response.data);
                setLoading(false);
            }
        })
        .catch((error) => {
            const { response } = error;
            catchError(setError, response)
            setLoading(false);
        });
};


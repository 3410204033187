import React, {useEffect, useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../i18n';
import {useTranslation} from "react-i18next";
import Footer from "../../components/footer";
import Header from "../../components/header";
import {catchError, getPayment} from "../../helpers/methods";
import {validationSchema} from "./validation";
import {currencies, errorValues, linkDataValues, payment, paymentOptions} from "../../enums";
import {endpoints} from "../../configs";
import axios from "axios";
import Loader from "react-dots-loader";
import {useSearchParams} from "react-router-dom";
import Error from "../../components/error";

const baseURL = `${process.env.PUBLIC_URL}/assets/`;

const PaymentForm = () => {
    const [isLoading, setLoading] = useState(true)
    const [partnerLogo, setPartnerLogo] = useState('')
    const [error, setError] = useState(errorValues)
    const [linkData, setLinkData] = useState(linkDataValues)
    const [quantity, setQuantity] = useState(1)
    const [showInfo, setShowInfo] = useState(true);
    const [selectedOption, setSelectedOption] = useState(paymentOptions[0].value);
    const {t, i18n} = useTranslation();
    const selectedLanguage = i18n.language;
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    const handleError = () => {
        // If the image fails to load, set the fallback source
        setPartnerLogo('');
    };

    const handleSelectPayment = (id) => {
        setSelectedOption(id);
    };
    const handleToggle = () => {
        setShowInfo(prevState => !prevState);
    };
    const handleIncrement = () => {
        setQuantity(prevValue => prevValue + 1);
    };

    const handleDecrement = () => {
        setQuantity(prevValue => (prevValue > 0 ? prevValue - 1 : 0)); // Prevent going below 0
    };

    const submitForm = (values) => {
        setLoading(true)

        axios.post(`${endpoints.postPayment}`, {
            ...values,
            requestId: id,
            amount: linkData.isFlexible ? values?.amount : linkData.amount,
            quantity: quantity,
            anonymous: !showInfo,
            paymentSystem: selectedOption,
            language: selectedLanguage
        })
            .then((response) => {
                if(response?.status === 200 && response?.data?.redirectUrl) {
                    window.location.href = response?.data?.redirectUrl;
                }
                setLoading(false)
            })
            .catch((error) => {
                const { response } = error;
                catchError(setError, response)
                setLoading(false)
            })
    }

    useEffect(() => {
        getPayment(setLoading, setLinkData, setError, setShowInfo, setPartnerLogo, id, i18n)
    } , [id, i18n]);

    return (
        <div className='wrapper-container'>
            <Header lang={!error.status && linkData.language.length} partnerLogo={partnerLogo} onError={handleError}/>
            <div className={`form-container ${error.status ? 'error' : ''}`}>
                {isLoading ?
                    <div className='overlay'>
                        <Loader color={'#FFFFFF'} size={16}/>
                    </div> : error.status ?
                        <Error {...error}/> :
                    <>
                        <div className='title'>{linkData.requestName}</div>
                        <div className='secondary-text'>{t('fillTheFields')}</div>
                        <Formik
                            initialValues={payment}
                            enableReinitialize
                            validationSchema={validationSchema(showInfo, linkData.isFlexible, linkData.amountMin, linkData.amountMax)}
                            onSubmit={submitForm}
                        >
                            {({ errors, isSubmitting}) => (
                                <Form>
                                    {
                                        linkData?.isFlexible ?
                                            <div className="form-group amount isFlexible">
                                                <label htmlFor="amount">{t('totalAmount')}</label>
                                                <Field
                                                    name="amount"
                                                    type="text"
                                                    className={errors["amount"]?.length ? 'error' :''}
                                                    placeholder={'0'}
                                                />
                                                <span className='currency'>{currencies[linkData.currency]}</span>
                                                <ErrorMessage className='error-message' name="amount" component="div" />
                                            </div>
                                            :
                                            <div className="form-group amount">
                                                {linkData.availableCount !== 1 &&
                                                    <>
                                                        {linkData.availableCount && <div className='text-center'>{t('quantity')}{' '}{linkData.availableCount}</div>}
                                                        <div className='quantity'>
                                                            <button type='button' className='counter' onClick={handleDecrement} disabled={quantity <= 1}>-</button>
                                                            <input
                                                                type="text"
                                                                value={quantity}
                                                                disabled
                                                            />
                                                            <button type='button' className='counter' onClick={handleIncrement} disabled={quantity >= linkData.availableCount}>+
                                                            </button>
                                                        </div>
                                                    </>}
                                                <div className='flex justify-between'>
                                                    <div>{t('totalAmount')}</div>
                                                    <div className='text-bold'>{(quantity*linkData.amount).toLocaleString('en-US')} {currencies[linkData.currency]}</div>
                                                </div>

                                            </div>

                                    }
                                    {linkData?.allowAnonymous &&
                                        <div className="form-group anonymous">
                                            <div className='flex'>
                                                <img src={`${baseURL}icons/anonymous.svg`} alt='Anonymous'/>
                                                <div>
                                                    <div className='secondary-text'>{t('anonymous')}</div>
                                                </div>
                                            </div>
                                            <div className={`toggle-container  ${!showInfo ? 'anonymous' : ''}`} onClick={handleToggle}>
                                                <div className={`toggleButton ${!showInfo ? 'anonymous' : ''}`}></div>
                                            </div>
                                        </div>
                                        }

                                    {(showInfo || !linkData?.allowAnonymous) &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="customerName">{t('customerName')}</label>
                                                <Field name="customerName" type="text" className={errors["customerName"]?.length ? 'error' :''}/>
                                                <ErrorMessage className='error-message' name="customerName" component="div" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="customerEmail">{t('customerEmail')}</label>
                                                <Field name="customerEmail" type="text" className={errors["customerEmail"]?.length ? 'error' :''} />
                                                <ErrorMessage className='error-message' name="customerEmail" component="div" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="customerMobile">{t('customerMobile')}</label>
                                                <Field name="customerMobile" type="text" className={errors["customerMobile"]?.length ? 'error' :''} />
                                                <ErrorMessage className='error-message' name="customerMobile" component="div" />
                                            </div>
                                        </>}

                                    <div className="form-group">
                                        <label htmlFor="paymentSystem">{t('paymentMethod')}</label>
                                        <Field
                                            name="paymentSystem"
                                            render={() => (
                                                <div className='options-container'>
                                                    {linkData?.paymentSystems.map(system =>
                                                        paymentOptions
                                                            .filter(option => option.value === system)
                                                            .map(option => (
                                                                <div
                                                                    key={option.id}
                                                                    onClick={() => handleSelectPayment(option.value)}
                                                                    className={`option ${selectedOption === option.value ? 'selected' : ''}`}
                                                                >
                                                                    <div className="icon-container">
                                                                        <img src={`${baseURL}icons/paymentSystems/${option.id}.svg`} alt={option.label} />
                                                                        {selectedOption === option.value && (
                                                                            <img src={`${baseURL}icons/check-circle.svg`} className="check-icon" alt="Selected" />
                                                                        )}
                                                                    </div>
                                                                    <span>{option.label}</span>
                                                                </div>
                                                            ))
                                                    )}

                                                </div>
                                            )}
                                        />
                                        <ErrorMessage className='error-message' name="paymentSystem" component="div" />
                                    </div>
                                    <button type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>{t('continue')}</button>
                                </Form>
                            )}
                        </Formik>
                    </>}
            </div>
            <Footer logo={partnerLogo}/>
        </div>
    );
}

export default PaymentForm;
